import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RedirectionService } from 'src/app/service/redirection.service';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectionComponent implements OnInit, OnDestroy {

  url : string; 
  redirectSubscriber : Subscription;

  constructor(private redirectionService : RedirectionService, private title: Title) {
    this.title.setTitle('301');
  }

  ngOnInit(): void {
    this.redirectSubscriber = this.redirectionService.getNewUrl().subscribe(newUrl => {
      if (newUrl) {
          this.url = newUrl;
          // Simulate redirection
          setTimeout(() => {
           window.location.replace(`${window.location.origin}${this.url}`);
          }, 800);
      }
    });
  }
  
  ngOnDestroy() {
    if (this.redirectSubscriber) {
      this.redirectSubscriber.unsubscribe();
    }
  }
}

