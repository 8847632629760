<footer class="pt-5 pb-4" style="background-color: black">
  <div class="footer-upper pb-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 mb-4 pe-4">
          <div class="footer-about">
            <a class="logo" routerLink="/" routerLinkActive="active">
              <img
                src="assets/images/lhd/LUXURY white.png"
                class="logo-img"
                alt="logo"
                width="100%"
                height="100px"
                loading="lazy"
              />
            </a>
            <ul style="margin-left: -32px">
              <li class="white">
                <strong>Call Us&nbsp;:</strong
                ><a href="tel:02087747298"> 020 8774 7298</a>
              </li>
              <li class="white">
                <strong>Email Us&nbsp;:</strong>
                <span class="emailfont"
                  ><a href="mailto:enquiries@luxuryholidaysdirect.com">
                    enquiries@luxuryholidaysdirect.com</a
                  ></span
                >
              </li>
              <li class="white">
                <strong>Website&nbsp;:</strong
                ><a routerLink="/"> www.luxuryholidaysdirect.com </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
          <div class="footer-links">
            <br />
            <span class="white font3 font5 custom-title">Quick links</span>
            <ul class="marg-ul" style="margin-left: -32px">
              <li><a routerLink="/about-us">About Us</a></li>
              <li><a routerLink="/contact-us">Contact Us</a></li>
              <li><a routerLink="/covid">Covid-19</a></li>
              <li><a routerLink="/travelwise">Travel Wise</a></li>
              <li><a routerLink="/request-quote">Request Quote</a></li>
              <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
              <li>
                <a routerLink="/booking-conditions">Booking Conditions</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
          <div class="footer-links">
            <span class="white custom-title">TOP LUXURY DESTINATIONS</span>
            <ul class="marg-ul" style="margin-left: -32px">
              <li>
                <a
                  [routerLink]="[
                    '/destinations',
                    'luxury-hotels-in-maldives',
                    3
                  ]"
                  >Maldives</a
                >
              </li>
              <li>
                <a [routerLink]="['/destinations', 'luxury-hotels-in-dubai', 6]"
                  >Dubai</a
                >
              </li>
              <li>
                <a
                  [routerLink]="[
                    '/destinations',
                    'luxury-hotels-in-mauritius',
                    1
                  ]"
                  >Mauritius</a
                >
              </li>
              <li>
                <a
                  [routerLink]="[
                    '/destinations',
                    'luxury-hotels-in-seychelles',
                    2
                  ]"
                  >Seychelles</a
                >
              </li>
              <li>
                <a
                  [routerLink]="[
                    '/destinations',
                    'luxury-hotels-in-thailand',
                    33
                  ]"
                  >Thailand</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div class="footer-links">
            <span class="white font3 custom-title">RECOMMENDED HOTELS</span>
            <div class="newsletter-form marg-ul">
              <p class="mb-3 white1">
                <a
                  [routerLink]="[
                    '/destinations',
                    'luxury-hotels-in-mauritius',
                    'the-westin-mauritius-turtle-bay-resort-spa',
                    7018
                  ]"
                  >The Westin Mauritius Turtle Bay Resort & Spa</a
                >
              </p>
              <p class="mb-3 white1">
                <a
                  [routerLink]="[
                    '/destinations',
                    'luxury-hotels-in-mauritius',
                    'the-residence-mauritius',
                    5082
                  ]"
                  >The Residence Mauritius</a
                >
              </p>
              <p class="mb-3 white1">
                <a
                  [routerLink]="[
                    '/destinations',
                    'luxury-hotels-in-mauritius',
                    'constance-le-prince-maurice',
                    5067
                  ]"
                  >Constance Le Prince Maurice</a
                >
              </p>
              <form
                action="#"
                method="get"
                accept-charset="utf-8"
                class="border-0 d-flex align-items-center"
              >
                <!-- <input type="text" placeholder="Email Address"> -->
                <a routerLink="/subscribe" class="nir-btn ms-2">Subscribe</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
      <div
        class="copyright-inner rounded p-3 d-md-flex align-items-center justify-content-between"
      >
        <div class="copyright-text">
          <p class="m-0 white">
            2024 Luxury Holidays Direct. All rights reserved.
          </p>
        </div>
        <div class="social-links">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/LuxuryHolidaysDirect/"
                target="_blank"
                aria-label="facebook"
              >
                <fa-icon [icon]="faFacebook" color="white"></fa-icon>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/luxuryholidaysdirect/"
                target="_blank"
                aria-label="instagram"
              >
                <fa-icon [icon]="faInstagram" color="white"></fa-icon>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/c/Luxuryholidaysdirect1?app=desktop"
                target="_blank"
                aria-label="youtube"
              >
                <fa-icon [icon]="faYoutube" color="white"></fa-icon>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/luxuryholidaysd?lang=en"
                target="_blank"
                aria-label="twitter"
              >
                <fa-icon [icon]="faTwitter" color="white"></fa-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="particles-js"></div>
</footer>
