import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectionComponent } from './component/error/redirection/redirection.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./component/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'destinations/:destination/:id',
    loadChildren: () =>
      import('./component/destinations/destinations.module').then(
        (m) => m.DestinationsModule
      ),
  },
  {
    path: 'offers-deals',
    loadChildren: () =>
      import('./component/offers/offers.module').then((m) => m.OffersModule),
  },
  {
    path: 'tours',
    loadChildren: () =>
      import('./component/tours/tours.module').then((m) => m.ToursModule),
  },
  {
    path: 'weddings-honeymoons',
    loadChildren: () =>
      import('./component/weddings/weddings.module').then(
        (m) => m.WeddingsModule
      ),
  },
  {
    path: 'destinations/:destination/:hotel/:id',
    loadChildren: () =>
      import('./component/sub/sub.module').then((m) => m.SubModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./component/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./component/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'themes/:theme',
    loadChildren: () =>
      import('./component/themes/themes.module').then((m) => m.ThemesModule),
  },
  {
    path: 'covid',
    loadChildren: () =>
      import('./component/covid/covid.module').then((m) => m.CovidModule),
  },
  {
    path: 'travelwise',
    loadChildren: () =>
      import('./component/travel-wise/travel-wise.module').then(
        (m) => m.TravelWiseModule
      ),
  },
  {
    path: 'tours/:hotel/:id',
    loadChildren: () =>
      import('./component/tour-packages/tour-packages.module').then(
        (m) => m.TourPackagesModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./component/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'request-quote',
    loadChildren: () =>
      import('./component/request-quote/request-quote.module').then(
        (m) => m.RequestQuoteModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./component/error/error.module').then((m) => m.ErrorModule),
    data: { status: 404 },
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./component/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'booking-conditions',
    loadChildren: () =>
      import('./component/booking-conditions/booking-conditions.module').then(
        (m) => m.BookingConditionsModule
      ),
  },
  {
    path: 'summer',
    loadChildren: () =>
      import('./component/summer/summer.module').then((m) => m.SummerModule),
  },
  {
    path: 'winter',
    loadChildren: () =>
      import('./component/winter/winter.module').then((m) => m.WinterModule),
  },
  {
    path: 'autumn',
    loadChildren: () =>
      import('./component/autumn/autumn.module').then((m) => m.AutumnModule),
  },
  {
    path: 'spring',
    loadChildren: () =>
      import('./component/spring/spring.module').then((m) => m.SpringModule),
  },
  { path: 'redirection', component: RedirectionComponent },
  {
    path: 'subscribe',
    loadChildren: () =>
      import('./component/souscription/souscription.module').then(
        (m) => m.SouscriptionModule
      ),
  },
  {
    path: 'articles/:documentId',
    loadChildren: () =>
      import('./component/single-article/single-article.module').then(
        (m) => m.SingleArticleModule
      ),
  },

  {
    path: 'categories/:category/:documentId',
    loadChildren: () =>
      import('./component/category/category.module').then(
        (m) => m.CategoryModule
      ),
  },
  // {
  //   path: 'generatexml',
  //   loadChildren: () =>
  //     import('./component/xml-generator/xml-generator.module').then(
  //       (m) => m.XmlGeneratorModule
  //     ),
  // },
  { path: '**', redirectTo: '/error' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
