import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

let DSN_DEV = "https://9b0f8633439f0da5e25632ceb94eb542@o4507343120891904.ingest.us.sentry.io/4507343127576576";
let  DSN_PROD = "https://0ad873450a10929da7d59a4e5904a16d@o4507357313302528.ingest.de.sentry.io/4507357327786064";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.production ? DSN_PROD : DSN_DEV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
