import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeAnnexComponent } from './home-annex.component';
import { RouterModule } from '@angular/router';
import { HomeAnnexRoutingModule } from './home-annex-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [HomeAnnexComponent],
  imports: [
    RouterModule,
    CommonModule,
    CarouselModule,
    HomeAnnexRoutingModule,
    FontAwesomeModule,
  ],
  exports: [HomeAnnexComponent],
})
export class HomeAnnexModule {}
