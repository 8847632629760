import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageCacheService {
  private cache: Map<string, any> = new Map<string, any>();

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  setItem(key: string, value: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cache.set(key, value);
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getItem(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      if (this.cache.has(key)) {
        return this.cache.get(key);
      }
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        const parsedValue = JSON.parse(storedValue);
        this.cache.set(key, parsedValue);
        return parsedValue;
      }
    }
    return null;
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cache.delete(key);
      localStorage.removeItem(key);
    }
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cache.clear();
      localStorage.clear();
    }
  }
}
