import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import {
  faStar,
  faGbp,
  faCalendar,
  faCutlery,
  faCheckCircle,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-home-annex',
  templateUrl: './home-annex.component.html',
  styleUrls: ['./home-annex.component.css'],
})
export class HomeAnnexComponent {
  faStar = faStar;
  faGbp = faGbp;
  faCalendar = faCalendar;
  faCutlery = faCutlery;
  faCheckCircle = faCheckCircle;
  faClock = faClock;
  constructor(private router: Router) {}

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplaySpeed: 2000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 0,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      360: { items: 1 },
      400: { items: 1 },
      500: { items: 1 },
      600: { items: 2 },
      740: { items: 2 },
      940: { items: 3 },
    },
    nav: false,
  };

  goTo(url: string): void {
    this.router.navigateByUrl(url);
  }
  lazyOptions: OwlOptions = {
    ...this.customOptions,
    lazyLoad: true,
  };
}
