import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnddetailsComponent } from './enddetails.component';
import { EnddetailsRoutingModule } from './enddetails-routing.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [EnddetailsComponent],
  imports: [
    CommonModule,
    EnddetailsRoutingModule,
    FontAwesomeModule
  ],
  exports:[EnddetailsComponent]
})
export class EnddetailsModule { }


