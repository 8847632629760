import { Component } from '@angular/core';
import { config } from '@fortawesome/fontawesome-svg-core';
import { RedirectionService } from './service/redirection.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
config.autoAddCss = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  isTemplateVisible: boolean = true;
  isFloatingBtnVisible: boolean = true;
  isHome: boolean = false;
  // Don't remove RedirectionService injection , it is used to do redirection
  constructor(
    private redirectionService: RedirectionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.logRedirection(event.urlAfterRedirects, event.url);
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isTemplateVisible = !(
          this.router.url.includes('error') ||
          this.router.url.includes('subscribe')
        );
        this.isFloatingBtnVisible = !this.router.url.includes('blog');
      });
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.isHome = this.router.url==="/";
      });
  }

  private logRedirection(redirectedUrl: string, originalUrl: string) {
    //console.log('Redirection detected:', redirectedUrl, originalUrl);
  }
}
