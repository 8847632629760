<style>
  @import url("https://fonts.cdnfonts.com/css/heybeibeh");
  .img__header {
    width: 100vw;
    height: 630px;
    object-fit: cover;
    object-position: center;
  }
  ::ng-deep .mat-mdc-text-field-wrapper {
    --mdc-filled-text-field-active-indicator-height: 0px !important;
    --mdc-filled-text-field-focus-active-indicator-height: 0px !important;
    --bs-body-bg: transparent !important;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }
  .banner-main {
    height: 630px;
    position: relative;
  }
  .banner .banner-main .banner-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top;
  }
  .banner-content {
    top: 45%;
    right: 0;
    left: 0;
    width: 85%;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%);
  }
  .white {
    color: #fff !important;
    text-shadow: -1px -1px 0 #0000004f, 1px -1px 0 #0000004f,
      -1px 1px 0 #0000004f, 1px 1px 0 #0000004f;
  }
  .banner-content > h1 {
    font-size: 58px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  .banner-content p,
  .counter_one .counter1 .counter-item i {
    font-size: 21px;
  }
  .font-loading {
    font-family: HeyBeibeh, sans-serif;
    color: #fff !important;
  }

  ::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: rgba(255, 0, 0, 0) !important;
    padding: 0;
    color: black !important;
  }
  ::ng-deep
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  ::ng-deep
    .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
    caret-color: #000 !important;
  }

  ::ng-deep .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: -25px;
  }
</style>
<div
  id="carouselExampleSlidesOnly"
  class="carousel slide"
  data-ride="carousel"
  [routerLink]="['/themes', 'honeymoon']"
>
  <div class="carousel-inner">
    <div class="carousel-item active aspect-ratio-container">
      <!-- <img
        class="d-block w-100"
        src="\assets\images\lhd\homehoneymoonbanner.webp"
        alt="Book your honeymoon with us"
        height="auto"
        loading="lazy"
      /> -->

      <picture>
        <source
          srcset="
            \assets\images\lhd\homehoneymoonbanner600.webp   600w,
            \assets\images\lhd\homehoneymoonbanner1200.webp 1200w,
            \assets\images\lhd\homehoneymoonbanner2000.webp 2000w
          "
          type="image/webp"
        />
        <img
          src="assets\images\lhd\homehoneymoonbanner1200.webp"
          srcset="
            \assets\images\lhd\homehoneymoonbanner600.webp   600w,
            \assets\images\lhd\homehoneymoonbanner1200.webp 1200w,
            \assets\images\lhd\homehoneymoonbanner2000.webp 2000w
          "
          sizes="100vw"
          alt="Description de l'image"
          style="width: 100%; height: auto"
          loading="lazy"
          width="600"
          height="400"
        />
      </picture>
    </div>
  </div>
</div>
<section class="our-team pb-2">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 mb-4">
        <div class="section-title text-center text-lg-start">
          <h2 class="theme1">Our Promise to You</h2>
          <p>
            <fa-icon [icon]="faCheckCircle"></fa-icon> A Luxury holidays company
            based here in the UK, tour operating for over 40 years
          </p>
          <p>
            <fa-icon [icon]="faCheckCircle"></fa-icon> Popular destination
            specialists guaranteed to answer your needs swiftly
          </p>
          <p>
            <fa-icon [icon]="faCheckCircle"></fa-icon> A family-owned
            independent business
          </p>
          <p>
            <fa-icon [icon]="faCheckCircle"></fa-icon> Price match guarantee
          </p>
          <p>
            <fa-icon [icon]="faCheckCircle"></fa-icon> Low deposits and flexible
            payment popcies
          </p>
          <p>
            <fa-icon [icon]="faCheckCircle"></fa-icon> Outstanding travel
            services - check out our reviews!
          </p>
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="text-center section-title text-lg-start">
          <h2 class="mt-4 theme1">Your Holiday Booking is Secure</h2>
          <p class="mb-0">
            We are ABTA licensed and ATOL bonded to protect your booking.
          </p>
          <div
            class="trend-image trend-image-2 position-relative pb-4 pt-5 aspect-ratio-container"
          >
            <img
              src="assets/images/lhd/threelogoo.webp"
              alt="image"
              class="three-logo"
              loading="lazy"
              width="600"
              height="400"
            />
          </div>
          <p>
            LuxuryHolidayDirect.com is a trading brand within SUNSET TRAVEL Ltd.
            The Company has been trading for over a quarter of a century.
          </p>
        </div>
      </div>
    </div>
    <p>
      Are you dreaming of a luxury holiday that will make you feel like a
      true<strong> VIP</strong>? Do you want to explore some of the most
      beautiful and<strong> exotic destinations</strong> in the world while
      enjoying the highest level of comfort and service? Do you want to find
      the<strong> best deals on luxury hotels</strong>, resorts, tours, and
      more?
    </p>
    <p>
      If you answered yes to any of these questions, then you have come to the
      right place. Luxury Holidays Direct is your one-stop shop for all your
      luxury travel needs. We are a family-owned independent business that has
      been<strong> operating for over 40 years</strong>. We have carefully
      selected the<strong> best luxury hotels and destinations</strong> around
      the globe and put together some amazing deals just for you.
    </p>
    <p>
      Whether you want to relax on a pristine beach in Maldives, enjoy the glitz
      and glamour of Dubai, discover the cultural diversity of Mauritius, or
      experience the natural beauty of Seychelles, we have something for
      everyone. We can also tailor-make your holiday to suit your preferences,
      budget, and special occasions.
    </p>
    <p>
      We are not just a travel agency we are your<strong>
        holiday travel experts</strong
      >. We have a team of experienced and knowledgeable staff who can help you
      plan every aspect of your trip, from flights and transfers to excursions
      and activities. We also offer 24/7 customer support, low deposits,
      flexible payment policies, price match guarantee, and ABTA and ATOL
      protection.
    </p>
    <p>
      So, what are you waiting for? Start planning your dream luxury holiday
      today with Luxury Holidays Direct. Browse our website to find inspiration,
      offers, deals, testimonials, reviews, and more. Or contact us today and
      let us help you create your perfect holiday package.
    </p>
  </div>
</section>
<div class="container">
  <div class="section-title w-75 mx-auto text-center">
    <h2 class="theme" style="margin: 32px 0 22.4px 0">
      <span class="" id="border">Top Holiday Inspiration</span>
    </h2>
    <p>
      Whether you want to explore the great outdoors, indulge yourself in fine
      dining or just relax at the beach, our affordable
      <strong> Luxury Holiday </strong> and
      <strong>package holiday </strong>offers are perfect to take that
      well-deserved break and travel the world. Since 1980,
      <strong> for over 40 years</strong>, we have carefully selected the best
      luxury hotels and destinations around the globe and put together some
      <strong> amazing deals </strong>
      just for you to give you the best experience possible while travelling.
    </p>
    <p>
      Choose your dates and book your perfect luxury holiday to travel to some
      of <strong> the most beautiful places and hotels on earth</strong>, now.
      Request your personalised quote from our incredible choice of beach
      holidays, resorts, city breaks, adventure tours, family holidays, the
      ideal wedding or honeymoon holiday for couples and
      <strong> luxury all-inclusive holidays </strong>, our luxury holidays have
      something for everyone.
    </p>
    <p>
      Are you looking for some inspiration for your next luxury holiday? Do you
      want to discover some of the most popular and trending destinations for
      luxury travel? Do you want to find out what are the best times to visit,
      what are the best deals to book, and what are the best things to do and
      see?
    </p>
    <p>
      If you answered yes to any of these questions, then you will love our blog
      section, where we share our top holiday inspiration for luxury travel. You
      can find articles, guides, tips, reviews, and more on various topics
      related to luxury holidays. You can also subscribe to our newsletter and
      get the latest updates and offers delivered to your inbox.
    </p>
    <p>
      Choose your dates and book your perfect luxury holiday to travel to some
      of <strong>the most beautiful places and hotels on earth</strong>, now.
      Request your personalised quote from our incredible choice of beach
      holidays, resorts, city breaks, adventure tours, family holidays, the
      ideal wedding or honeymoon holiday for couples and<strong>
        luxury all-inclusive holidays</strong
      >
      , our luxury holidays have something for everyone.
    </p>
  </div>
</div>
<section class="our-team pb-0">
  <div class="container">
    <div class="section-title mb-6 w-75 mx-auto text-center">
      <h2 class="mb-1 theme">Top Travel Destinations</h2>
    </div>
    <div class="team-main">
      <div class="row shop-slider">
        <div
          class="col-lg-3 col-md-6 col-sm-12 mb-4 cursor-pointer"
          (click)="goTo('/destinations/luxury-hotels-in-Maldives/3')"
        >
          <div class="team-list rounded">
            <div class="team-image aspect-ratio-container">
              <img
                src="assets/images/lhd/Maldives.webp"
                class="img-team"
                alt="team"
                loading="lazy"
                width="600"
                height="400"
              />
            </div>
            <div class="team-content text-center p-c bg-theme team__content">
              <h3 class="mb-0 white font">Luxury Holidays to Maldives</h3>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-12 mb-4 cursor-pointer"
          (click)="goTo('/destinations/luxury-hotels-in-Mauritius/1')"
        >
          <div class="team-list rounded">
            <div class="team-image aspect-ratio-container">
              <img
                src="assets/images/lhd/Mauritius.webp"
                class="img-team"
                alt="team"
                loading="lazy"
                width="600"
                height="400"
              />
            </div>
            <div class="team-content text-center p-c bg-theme team__content">
              <h3 class="mb-0 white font">Luxury Holidays to Mauritius</h3>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-12 mb-4 cursor-pointer"
          (click)="goTo('/destinations/luxury-hotels-in-dubai/6')"
        >
          <div class="team-list rounded">
            <div class="team-image aspect-ratio-container">
              <img
                src="assets/images/lhd/Dubai.webp"
                class="img-team"
                alt="team"
                loading="lazy"
                width="600"
                height="400"
              />
            </div>
            <div class="team-content text-center p-c bg-theme team__content">
              <h3 class="mb-0 white font">Luxury Holidays to Dubai</h3>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-sm-12 mb-4 cursor-pointer"
          (click)="goTo('/destinations/luxury-hotels-in-Seychelles/2')"
        >
          <div class="team-list rounded aspect-ratio-container">
            <div class="team-image">
              <img
                src="assets/images/lhd/Seychelles.webp"
                class="img-team"
                alt="team"
                loading="lazy"
                width="600"
                height="400"
              />
            </div>
            <div class="team-content text-center bg-theme p-c team__content">
              <h3 class="mb-0 white font">Luxury Holidays to Seychelles</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="container mb-5">
  <div class="row">
    <p>
      We offer luxury holidays to some of the most beautiful and exotic
      destinations in the world. Whether you want to experience the culture,
      history, nature, or nightlife of these places, we have the perfect holiday
      package for you. Here are some of our top travel destinations for luxury
      holidays:
    </p>
    <div class="col-3 partners">
      <div class="col-lg-12 col-md-12 col-sm-6 mt-5 p-0">
        <h3>We're in good company</h3>
        <p>Discover the partners who share our journey</p>
      </div>
    </div>
    <div class="col-9 logoos">
      <owl-carousel-o [options]="lazyOptions">
        <ng-template carouselSlide>
          <div
            class="col-lg-3 col-md-3 col-sm-3 mt-2 slider aspect-ratio-container"
          >
            <img
              src="assets/images/lhd/logos-01.webp"
              width="100%"
              height="190px"
              style="object-fit: contain; object-position: center"
              alt="British Airways"
              loading="lazy"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div
            class="col-lg-3 col-md-3 col-sm-3 mt-2 slider aspect-ratio-container"
          >
            <img
              src="assets/images/lhd/britishh.webp"
              width="100%"
              height="190px"
              style="object-fit: contain; object-position: center"
              alt="Emirates"
              loading="lazy"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div
            class="col-lg-3 col-md-3 col-sm-3 mt-2 slider aspect-ratio-container"
          >
            <img
              src="assets/images/lhd/emi.webp"
              width="100%"
              height="190px"
              style="object-fit: contain; object-position: center"
              alt="Etihad"
              loading="lazy"
            />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div
            class="col-lg-3 col-md-3 col-sm-3 mt-2 slider aspect-ratio-container"
          >
            <img
              src="assets/images/lhd/qatarair.webp"
              width="100%"
              height="190px"
              style="object-fit: contain; object-position: center"
              alt="Qatar Airways"
              loading="lazy"
            />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <p>
      We are proud to work with some of the best partners in the travel
      industry, who share our vision and values of providing the ultimate luxury
      travel experiences to our customers. From airlines and hotels to tour
      operators and media outlets, we have established strong and long-lasting
      relationships with our partners, who help us deliver the best service and
      quality to our customers.
    </p>
  </div>
</div>
<section class="featured-counter bg-theme">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7"></div>
      <div class="col-lg-12">
        <div class="counter text-center d-inline-block w-100 mt-0 wid-box">
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-4">
              <div
                class="counter-item p-3 border-all rounded d-flex flex-column justify-content-between align-items-center gap-4"
              >
                <div
                  class="trend-image trend-image-1 position-relative aspect-ratio-container"
                >
                  <img
                    src="assets/images/lhd/propaganda.png"
                    alt="image"
                    width="90%"
                    style="object-fit: contain; object-position: center"
                    height="96px"
                    loading="lazy"
                  />
                </div>
                <div class="text-contener">
                  <h4 class="white white3" style="min-height: 70px">
                    Request a Brochure
                  </h4>

                  <p class="m-0 white">
                    Email us on:
                    <span class="font" style="color: #fdc703"
                      ><a
                        href="mailto:luxuryholidaysdirect.com"
                        target="_blank"
                        class="font1"
                        >@luxuryholidaysdirect.com</a
                      ></span
                    >
                    for our tailor made luxury holiday tours - 2024/2025.
                    Request a brochure or view our online brochure
                  </p>
                </div>
                <div class="form-group mb-0 text-center">
                  <a
                    href="assets/pdf/LHD_brochure.pdf"
                    target="_blank"
                    class="nir-btn w-100 make-visible"
                  >
                    Online Brochure Here</a
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div
                class="counter-item p-3 border-all rounded d-flex flex-column justify-content-between align-items-center gap-4"
              >
                <div
                  class="trend-image trend-image-1 position-relative aspect-ratio-container"
                >
                  <img
                    src="assets/images/lhd/notes.png"
                    alt="image"
                    width="90%"
                    style="object-fit: contain; object-position: center"
                    height="96px"
                    loading="lazy"
                  />
                </div>
                <div class="text-contener">
                  <h4 class="white white3" style="min-height: 70px">
                    Request a Quote / Visit in-person
                  </h4>
                  <p class="m-0 white">
                    Fill out our 'Request Quote' form and an agent will contact
                    you.
                  </p>
                </div>
                <div class="form-group mb-0 text-center">
                  <a
                    href=""
                    class="nir-btn w-100 make-visible"
                    routerLink="/request-quote"
                  >
                    Get a Quote Today</a
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div
                class="counter-item p-3 border-all rounded d-flex flex-column justify-content-between align-items-center gap-4"
              >
                <div
                  class="trend-image trend-image-1 position-relative aspect-ratio-container"
                >
                  <img
                    src="assets/images/lhd/mail.png"
                    alt="image"
                    width="90%"
                    style="object-fit: contain; object-position: center"
                    height="96px"
                    loading="lazy"
                  />
                </div>
                <div class="text-contener">
                  <h4 class="white white3" style="min-height: 70px">
                    Sign up to our newsletter
                  </h4>

                  <p class="m-0 white">
                    Subscribe here and receive holiday offers up to 50% off!
                  </p>
                </div>
                <div class="form-group mb-0 text-center">
                  <!--                  <a-->
                  <!--                    href="https://newsletter.luxuryholidaysdirect.com/index.php?action=form&amp;id=5115"-->
                  <!--                    target="_blank"-->
                  <!--                    class="nir-btn w-100 style1"-->
                  <!--                    >Sign up Now-->
                  <!--                  </a>-->
                  <a routerLink="/subscribe" class="nir-btn w-100 make-visible"
                    >Sign up Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="trending pt-6 pb-0 bg-lgrey">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="single-content">
          <div id="single-review" class="single-review mb-4">
            <div class="row d-flex align-items-center">
              <div class="col-lg-6 col-md-6">
                <div class="review-box bg-title text-center py-4 p-2 rounded">
                  <h3 class="white mb-1">Speak to one of our travel experts</h3>
                  <p class="mb-0 white font-italic contacting">
                    <a
                      class="ms-1 dec-hover-only"
                      href="tel:02087747298"
                      style="color: #fff"
                      >Call : 020 8774 7298</a
                    >
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="review-box bg-title text-center py-4 p-2 rounded">
                  <h3 class="white mb-1">
                    Our travel experts are here to help
                  </h3>
                  <p class="mb-0 white font-italic contacting">
                    Support - 7/7 days
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="our-partner pt-0 pb-5">
  <div class="container">
    <div class="section-title mb-6 w-75 mx-auto text-center">
      <h2 class="mb-1"><span class="theme">What Our Customers Think</span></h2>
    </div>
    <div class="row align-items-center partner-in partner-slider">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <div class="col-md-3 col-sm-6">
            <a
              href="https://www.feefo.com/en-GB/reviews/luxury-holidays-direct/660ebe14e4b0dbde675ebb0b/customer-review-"
              target="_blank"
            >
              <div class="rating-main d-flex align-items-center pb-2">
                <div class="rating">
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                </div>
              </div>
              <p style="font-weight: bold">
                <a
                  href="https://www.feefo.com/en-GB/reviews/luxury-holidays-direct/660ebe14e4b0dbde675ebb0b/customer-review-"
                  target="_blank"
                  class="colors"
                  >Perfect holiday</a
                >
              </p>
              <p>
                Dee was excellent as usual. We have used her over many years
                .<br />DAVID WATSON - March 02, 2024
              </p>
            </a>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="col-md-3 col-sm-6">
            <a
              href="https://www.feefo.com/en-GB/reviews/luxury-holidays-direct/660ebe13e4b0dbde675eb8fc/customer-review-excellent-service"
              target="_blank"
            >
              <div class="rating-main d-flex align-items-center pb-2">
                <div class="rating">
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                </div>
              </div>
              <p style="font-weight: bold">
                <a
                  href="https://www.feefo.com/en-GB/reviews/luxury-holidays-direct/660ebe14e4b0dbde675ebb0b/customer-review-"
                  target="_blank"
                  class="colors"
                  >excellent-service</a
                >
              </p>
              <p>
                Excellent service again making our holiday booking a pleasure<br />
                CARL CAHILL - April 29, 2023
              </p>
            </a>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="col-md-3 col-sm-6">
            <a
              href="https://www.feefo.com/en-GB/reviews/luxury-holidays-direct/6556053be4b0beae149beb57/customer-review-always-a-pleasure-never-a-chore"
              target="_blank"
            >
              <div class="rating-main d-flex align-items-center pb-2">
                <div class="rating">
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                  <fa-icon [icon]="faStar"></fa-icon>
                </div>
              </div>
              <p style="font-weight: bold">
                <a
                  href="https://www.feefo.com/en-GB/reviews/luxury-holidays-direct/6500543ae4b09100526617b8/customer-review-"
                  target="_blank"
                  class="colors"
                  >Always a pleasure never a chore</a
                >
              </p>
              <p>
                I have used them for a number of years now to travel all around
                the world...<br />TONY NELSON - February 27, 2023
              </p>
            </a>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
