import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SouscriptionComponent } from './souscription.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SouscriptionRoutingModule } from './souscription-routing.module';

@NgModule({
  declarations: [SouscriptionComponent],
  imports: [CommonModule, SouscriptionRoutingModule/*, DashboardModule*/],
  exports:[SouscriptionComponent]
})
export class SouscriptionModule {}
