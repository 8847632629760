import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SouscriptionComponent} from "./souscription.component";


const routes: Routes = [ {
  path: '',
  component: SouscriptionComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SouscriptionRoutingModule { }
