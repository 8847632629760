import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

  private urlSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getNewUrl(): Observable<any> {
    return this.urlSubject.asObservable();
  }
  updateNewUrl(newUrl: string) {
    this.urlSubject.next(newUrl);
  }

  constructor(private router : Router) {
    this.router.events.subscribe(event => {
      this.runRedirection(event);
    });
  }

  runRedirection(event : any) : void {
    if (event instanceof NavigationEnd) {
      if (this.router.url === '/error') {
          for (const [key , value] of oldAndNewURLMapping) {
            if (event?.url?.includes(key)) {
              this.router.navigateByUrl('redirection');
              // Update the new url in the component redirection
              this.updateNewUrl(`/${value}`);
              return ;
            } 
          }
      }
      
    }
  }
}

const oldBaseUrl = 'DestinationPages/';
const newBaseUrl = 'destinations/luxury-hotels-in-';
// [ oldUrl , newUrl ]
export const oldAndNewURLMapping : Map<string, string> = new Map([
  [`${oldBaseUrl}Abu-Dhabi`, `${newBaseUrl}abu-dhabi/8`],
  [`${oldBaseUrl}Anguilla`, `${newBaseUrl}anguilla/85`],
  [`${oldBaseUrl}Antigua`, `${newBaseUrl}antigua/16`],
  [`${oldBaseUrl}Barbados`, `${newBaseUrl}barbados/18`],
  [`${oldBaseUrl}Bermuda`, `${newBaseUrl}bermuda/55`],
  [`${oldBaseUrl}Boston`, `${newBaseUrl}boston/77`],
  [`${oldBaseUrl}Cambodia`, `${newBaseUrl}cambodia/38`],
  [`${oldBaseUrl}Costa-Rica`, `${newBaseUrl}costa-rica/141`],
  [`${oldBaseUrl}Dominican-Republic`, `${newBaseUrl}dominican-republic/86`],
  [`${oldBaseUrl}Dubai`, `${newBaseUrl}dubai/6`],
  [`${oldBaseUrl}Florida-Keys`, `${newBaseUrl}florida-keys/79`],
  [`${oldBaseUrl}Grenada`, `${newBaseUrl}grenada/57`],
  [`${oldBaseUrl}Grenadines`, `${newBaseUrl}grenadines/25`],
  [`${oldBaseUrl}Hawaii`, `${newBaseUrl}hawaii/84`],
  [`${oldBaseUrl}Hong-Kong`, `${newBaseUrl}hong-kong/29`],
  [`${oldBaseUrl}India`, `${newBaseUrl}india/40`],
  [`${oldBaseUrl}Indonesia`, `${newBaseUrl}indonesia/62`],
  [`${oldBaseUrl}Jamaica`, `${newBaseUrl}jamaica/28`],
  [`${oldBaseUrl}Las-Vegas`, `${newBaseUrl}las-vegas/80`],
  [`${oldBaseUrl}Los-Angeles`, `${newBaseUrl}los-angeles/82`],
  [`${oldBaseUrl}Malaysia`, `${newBaseUrl}malaysia/31`],
  [`${oldBaseUrl}Maldives`, `${newBaseUrl}maldives/3`],
  [`${oldBaseUrl}Mauritius`, `${newBaseUrl}mauritius/1`],
  [`${oldBaseUrl}Mexico`, `${newBaseUrl}mexica/51`],
  [`${oldBaseUrl}Miami`, `${newBaseUrl}miami/78`],
  [`${oldBaseUrl}Morocco`, `${newBaseUrl}morocco/72`],
  [`${oldBaseUrl}Oman`, `${newBaseUrl}oman/11`],
  [`${oldBaseUrl}Philippines`, `${newBaseUrl}philippines/37`],
  [`${oldBaseUrl}Reunion`, `${newBaseUrl}reunion/75`],
  [`${oldBaseUrl}San-Diego`, `${newBaseUrl}san-diego/83`],
  [`${oldBaseUrl}San-Francisco`, `${newBaseUrl}san-francisco/81`],
  [`${oldBaseUrl}Seychelles`, `${newBaseUrl}seychelles/2`],
  [`${oldBaseUrl}Singapore`, `${newBaseUrl}singapore/32`],
  [`${oldBaseUrl}South-Africa`, `${newBaseUrl}south-africa/46`],
  [`${oldBaseUrl}Sri-Lanka`, `${newBaseUrl}sri-lanka/41`],
  [`${oldBaseUrl}St-Lucia`, `${newBaseUrl}st-lucia/23`],
  [`${oldBaseUrl}Tahiti`, `${newBaseUrl}tahiti/69`],
  [`${oldBaseUrl}Thailand`, `${newBaseUrl}thailand/33`],
  [`${oldBaseUrl}Vietnam`, `${newBaseUrl}vietnam/36`],
  [`${oldBaseUrl}Zanzibar`, `${newBaseUrl}zanzibar/74`],
  [`offers`, `offers-deals`]
]); 