import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-enddetails',
  templateUrl: './enddetails.component.html',
  styleUrls: ['./enddetails.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnddetailsComponent {
  faFacebook = faFacebook;
  faYoutube = faYoutube;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
}
