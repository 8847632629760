import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Hotel } from '../models/hotel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = environment.API_BASE_URL;
  this: any;
  getMoreData: any;

  constructor(private http: HttpClient) {}

  getHotels(): Observable<Hotel[]> {
    const url = `${this.apiUrl}` + 'hotels';
    return this.http.get<Hotel[]>(url);
  }

  gethotel(id: number): Observable<Hotel> {
    const url = `${this.apiUrl}` + 'hotels' + '/' + `${id}`;
    return this.http.get<Hotel>(url);
  }

  getContinents(): Observable<any> {
    const url = `${this.apiUrl}` + 'destinations/destination-continent';
    return this.http.get<any>(url);
  }

  getDestinations(
    destinationId: string | undefined,
    destinationName: string
  ): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'destinations/' +
      destinationName +
      '/' +
      destinationId;
    return this.http.get<any>(url);
  }
  gettopOffers(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/top-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }
  getOffers(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/get-offers';
    return this.http.post<any>(url, {
      categoryDTOList: [],
      durationDTOList: [],
      minMaxPrices: [],
    });
  }
  getImage(apiUrl: string): Observable<Blob> {
    return this.http.get(apiUrl, { responseType: 'blob' });
  }

  gethoneymoonoffer(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/honeymoon-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }
  gettopHoneymoonOffer(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/top-honeymoon-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }

  gettopExclusiveHoneymoonOffer(): Observable<any> {
    const url = `${this.apiUrl}` + 'offers/top-honeymoon-exclusive-offers';
    return this.http
      .get<any>(url, {
        observe: 'events',
        reportProgress: true,
        responseType: 'text' as 'json',
      })
      .pipe(
        filter((e: any) => e.type === HttpEventType.Response && e.body),
        map((e) => e.body.trim().split(/\r\n|\r|\n/))
      );
  }

  getDestination(): Observable<any> {
    const url = `${this.apiUrl}` + 'destinations';
    return this.http.get<any>(url);
  }
  getHotelNotes(hotelId: number): Observable<Hotel[]> {
    const url = `${this.apiUrl}hotel-supplement/notes?hotelId=${hotelId}`;
    return this.http.get<Hotel[]>(url);
  }
  getTravel(hotelId: number): Observable<Hotel[]> {
    const url = `${this.apiUrl}hotel-supplement/travel?hotelId=${hotelId}`;
    return this.http.get<Hotel[]>(url);
  }
  getHoneymoon(): Observable<any> {
    const url =
      `${this.apiUrl}` + 'hotels/getHotelByCategory?HotelCategory=HONEYMOON';
    return this.http.get<any>(url);
  }
  getBeach(): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'hotels/getHotelByCategory?HotelCategory=BEACH_HOLIDAY';
    return this.http.get<any>(url);
  }
  getFamily(): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'hotels/getHotelByCategory?HotelCategory=FAMILY_FRIENDS';
    return this.http.get<any>(url);
  }
  getWild(): Observable<any> {
    const url =
      `${this.apiUrl}` + 'hotels/getHotelByCategory?HotelCategory=WILD_NATURE';
    return this.http.get<any>(url);
  }
  getAllinclusive(): Observable<any> {
    const url =
      `${this.apiUrl}` +
      'hotels/getHotelByCategory?HotelCategory=ALL_INCLUSIVE';
    return this.http.get<any>(url);
  }
  getTour(): Observable<any> {
    const url = `${this.apiUrl}` + 'tours';
    return this.http.get<any>(url);
  }
  getTourpackage(tourHotelId: number): Observable<any> {
    const url = `${this.apiUrl}tours/${tourHotelId}?tourHotelId=${tourHotelId}`;
    return this.http.get<any>(url);
  }
}
